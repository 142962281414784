body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem;
}

header nav a {
  color: white;
  margin: 0 1rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

header nav a:hover {
  color: #007bff;
}

main {
  padding: 2rem;
}

h1, h2, h3 {
  color: #333;
  font-family: 'Merriweather', serif;
}

footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.portfolio {
  padding: 2rem 0;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.portfolio-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.portfolio-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.portfolio-content {
  padding: 1rem;
}

.portfolio-content h3 {
  margin: 0 0 0.5rem;
}

.portfolio-content p {
  margin: 0 0 1rem;
  color: #666;
}

.portfolio-content a {
  display: block;
  margin: 0.5rem 0;
  color: #007bff;
  transition: color 0.3s ease;
}

.portfolio-content a:hover {
  color: #0056b3;
}

form div {
  margin-bottom: 1rem;
}

form label {
  display: block;
}

form input, form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  transition: border 0.3s ease;
}

form input:focus, form textarea:focus {
  border-color: #007bff;
}

form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #0056b3;
}

/* Skills Styling */
.skills {
  padding: 2rem 0;
}

.skills-category {
  margin-bottom: 2rem;
}

.skills-category h3 {
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.skills-category ul {
  list-style: none;
  padding: 0;
}

.skills-category ul li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.skills-category ul li svg {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

/* Primary Skills Highlighting */
.skills-category.primary ul li {
  font-weight: bold;
}

/* About Section Styling */
.about-section {
  display: flex;
  height: 20vw;
  background: rgba(114, 112, 112, 0.5); /* Black with 50% transparency */
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.about-content {
  flex: 0 0 40%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.about-image {
  flex: 0 0 60%;
  background: url('./assets/background-w.jpg') center center/cover no-repeat; /* Add your developer image here */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.developer-image {
  width: 200px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text p {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.about-text p:first-of-type {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.about-text p strong {
  color: #007bff;
}

.latest-projects {
  margin-top: 2rem;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.portfolio-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.portfolio-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.portfolio-content {
  padding: 1rem;
}

.portfolio-content h3 {
  margin: 0 0 0.5rem;
}

.portfolio-content p {
  margin: 0 0 1rem;
  color: #666;
}

.portfolio-content a {
  display: block;
  margin: 0.5rem 0;
  color: #007bff;
  transition: color 0.3s ease;
}

.portfolio-content a:hover {
  color: #0056b3;
}

/* About Section Styling */
.about {
  padding: 2rem;
}

.about-content {
  max-width: 80vw;
  margin: 0 auto;
}

.about-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-content p {
  margin-bottom: 1rem;
  color: #333;
}

.about-content p strong {
  color: #007bff;
}

.about-content h2 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.timeline {
  margin-top: 1rem;
  border-left: 2px solid #ddd;
  padding-left: 1rem;
}

.timeline-item {
  margin-bottom: 1rem;
  position: relative;
}

.timeline-item h3 {
  margin: 0;
  font-size: 1.5rem;
}

.timeline-item h4 {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  color: #666;
}

.timeline-item h4 span {
  font-weight: normal;
  color: #999;
}

.timeline-item p {
  margin: 0.5rem 0 0;
  color: #333;
}

.about-text {
  display: flex;
  align-items: center;
}

.about-text img {
  width: 100px; /* Adjust the width as needed */
  height: auto;
  border-radius: 50%;
  margin-right: 1rem;
}